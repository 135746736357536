<template>
  <div>
    <div v-if="!userStats || userStatsHistory?.error" class="flex grid lg:grid-cols-12 w-full gap-6">
      <div class="col-span-12 sm:col-span-9 flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <h1 class="text-text-primary font-semibold text-[24px]">Refrag Coach</h1>
          <div class="text-[10px] rounded-md px-1 py-[1px] mr-1 self-start" style="background-color: rgba(208, 113, 197, 0.125); color: rgb(208, 113, 197);">Beta</div>
        </div>
        <div class="flex-1 flex flex-col gap-2 w-full bg-gray-900 rounded-lg p-10 items-center justify-center relative">
          <div class="absolute top-0 left-0 w-full h-full topography-bg">
          </div>
          <div v-if="!showMatches" class="flex flex-col gap-4 text-center items-center z-50">
            <div
              class="min-w-[48px] w-[48px] h-[48px] rounded-full flex justify-center items-center button-bg"
            >
              <img class="w-6 h-6" src="../../assets/icons/stats-icon.svg">
            </div>
            <h1 class="text-text-primary font-medium text-lg">Refrag Coach is currently disabled</h1>
            <h2 class="text-text-secondary text-sm leading-5">Refrag Coach is currently disabled due to a site update. <br/>Check out our <a href="https://discord.gg/refrag" target="_blank" class="underline">Discord</a> for updates!</h2>
          </div>
          <div v-else-if="user.access_level < 1" class="flex flex-col gap-4 text-center items-center z-50">
            <div
              class="min-w-[48px] w-[48px] h-[48px] rounded-full flex justify-center items-center button-bg"
            >
              <img class="w-6 h-6" src="../../assets/icons/stats-icon.svg">
            </div>
            <h1 class="text-text-primary font-medium text-lg">Unlock Your Performance Insights!</h1>
            <h2 class="text-text-secondary text-sm leading-5">Get actionable insights, track your progress, and see detailed <br/>breakdowns that will help you elevate your game.</h2>
            <button @click="router.push('/upgrade')" class="py-3 w-2/3 rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2">
              Upgrade
              <ArrowRightIcon class="w-4 h-4 text-text-primary" />
            </button>
          </div>
          <div v-else-if="(userSettings?.has_valve_matches && userSettings?.match_auth_token) || userSettings?.faceit_player_id" class="flex flex-col gap-4 text-center items-center z-50">
            <h1 class="text-text-primary font-medium text-lg">Play more matches!</h1>
            <h2 class="text-text-secondary text-sm leading-5">
              Play more matches in order for the Refrag Coach
              <br> to start giving you recommendations for your performance!
              <br>
              If this is wrong, <a href="https://discord.com/invite/Refrag" target="_blank" class="underline">contact support</a>
            </h2>
          </div>
          <div v-else-if="(!userSettings?.has_valve_matches || !userSettings?.match_auth_token) && !userSettings?.faceit_player_id" class="flex flex-col gap-4 text-center items-center z-50">
            <h1 class="text-text-primary font-medium text-lg">Update your tokens!</h1>
            <h2 class="text-text-secondary text-sm leading-5">Add your match token, steam auth code, and Faceit username <br/>to start analyzing your performance with Refrag Coach.</h2>
            <button @click="router.push('/settings')" class="py-3 w-2/3 rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2">
              Go to Settings
              <ArrowRightIcon class="w-4 h-4 text-text-primary" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="!showMatches" class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start">
        <h1 class="text-text-primary font-semibold text-[24px]">Coach Routine</h1>
        <div class="flex-1 bg-gray-900 rounded-lg p-4 flex flex-col gap-2">
          <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
            <img src="../../assets/icons/routines.svg" class="w-8 h-8">
          </div>

          <h1 class="text-text-primary">
            Refrag Coach is currently disabled
          </h1>

          <h2 class="text-text-secondary text-xs font-light flex-1">
            Refrag Coach is currently disabled due to a site update. <br/>Check out our <a href="https://discord.gg/refrag" target="_blank" class="underline">Discord</a> for updates!
          </h2>
        </div>
      </div>

      <div v-else-if="user.access_level < 1" class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start">
        <h1 class="text-text-primary font-semibold text-[24px]">Coach Routine</h1>
        <div class="flex-1 bg-gray-900 rounded-lg p-4 flex flex-col gap-2">
          <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
            <img src="../../assets/logos/refrag-icon.svg" class="w-8 h-8">
          </div>

          <h1 class="text-text-primary">
            Upgrade to get a daily coach routine
          </h1>

          <h2 class="text-text-secondary text-xs font-light flex-1">
            Unlock your potential with our Refrag Coach! Get personalized recommendations for your most-played map.
          </h2>

          <button @click="router.push('/upgrade')" class="mt-6 py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-gray-800 hover:bg-gray-700 flex items-center justify-center gap-2">
            Upgrade Now
          </button>
        </div>
      </div>

      <div v-else-if="userSettings?.has_valve_matches && userSettings?.faceit_player_id && userSettings?.match_auth_token" class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start">
        <h1 class="text-text-primary font-semibold text-[24px]">Recommended</h1>
        <div class="flex-1 bg-gray-900 rounded-lg p-4 flex flex-col gap-2">
          <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
            <img src="../../assets/logos/refrag-icon.svg" class="w-8 h-8">
          </div>

          <h1 class="text-text-primary">
            Play more matches!
          </h1>

          <h2 class="text-text-secondary text-xs font-light flex-1">
            Play more matches so that Refrag coach can give you recommendations based on your performance!
          </h2>
        </div>
      </div>

      <div v-else class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start">
        <h1 class="text-text-primary font-semibold text-[24px]">Recommended</h1>
        <div class="flex-1 bg-gray-900 rounded-lg p-4 flex flex-col gap-2">
          <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
            <img src="../../assets/logos/refrag-icon.svg" class="w-8 h-8">
          </div>

          <h1 class="text-text-primary">
            Add Tokens to get started
          </h1>

          <h2 class="text-text-secondary text-xs font-light flex-1">
            Update your tokens to get started with Refrag Coach. Get personalized recommendations for your most-played map.
          </h2>

          <button @click="router.push('/settings')" class="mt-6 py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-gray-800 hover:bg-gray-700 flex items-center justify-center gap-2">
            Go to Settings
          </button>
        </div>
      </div>
    </div>
    <div v-else class="flex grid lg:grid-cols-12 w-full gap-6">
      <div class="col-span-12 sm:col-span-9 flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <h1 class="text-text-primary font-semibold text-[24px]">Refrag Coach</h1>
          <div class="text-[10px] rounded-md px-1 py-[1px] mr-1 self-start" style="background-color: rgba(208, 113, 197, 0.125); color: rgb(208, 113, 197);">Beta</div>
          <div class="has-tooltip">
          <img src="../../assets/icons/info-icon.svg" alt="Avatar" class="w-6 h-6 cursor-pointer" />
          <div class="tooltip bg-gray-900 rounded-lg p-2 ml-8 -mt-7">
            <p class="text-text-secondary text-xs">Comparison of your past {{ props?.userStatsHistory?.length }} matches to your rank's average</p>
          </div>
          </div>
        </div>
        <div class="bg-gray-900 rounded-lg flex flex-col flex-grow">
          <nav class="w-full grid grid-cols-3 items-center justify-center relative">
            <div v-for="(stat, index) in userStats.slice(0, 3)" :key="index"
              class="py-3 flex flex-col gap-2 col-span-1 items-center justify-center cursor-pointer relative border-b border-gray-800"
              @click="selectStat(stat)"
            >
              <h2 class="text-text-secondary text-xs">{{ toTitleCase(formatStatString(stat.data.name)) }}</h2>
            </div>
            <div class="sliding-border" :style="{ left: slidingBorderPosition }"></div>
          </nav>

          <div class="flex p-4 gap-4 sm:gap-16 flex-grow flex-col sm:flex-row">
            <div class="flex-1 flex flex-col gap-3">
              <h1 class="text-text-primary font-medium text-lg">
                {{ toTitleCase(formatStatString(selectedStat?.data?.name)) }}
              </h1>

              <div class="text-text-secondary text-sm xl:w-3/4">
                Your {{ formatStatString(selectedStat?.data?.name) }} average was
                <span :class="selectedStat?.data?.is_negative ? 'text-danger-400' : 'text-success-400'">
                  {{ selectedStat?.data.is_negative ? 'worse' : 'better' }} by
                  {{ Math.abs(parseFloat(parseFloat(selectedStat?.data.percent_difference).toFixed(2))) }}%
                </span>
                than your rank's average.
              </div>

              <div class="w-full h-px bg-gray-800 xl:w-4/5"/>

              <div class="text-text-secondary text-sm xl:w-3/4">
                {{ selectedStat?.data?.is_negative ? 'To improve' : 'To maintain or further improve' }} your {{ formatStatString(selectedStat?.data?.name) }}, play the prepared set by clicking the button below.
              </div>

              <button @click="launchRecommendedMod(selectedStatMod?.modifier?.modifier_name, true)" class="mt-auto py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2">
                {{ selectedStat?.data.is_negative ? 'Improve Now' : 'Practice Now' }}
                <svg
                  v-if="launchLoading === 'prepare_set'"
                  width="16"
                  height="16"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                >
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
                  <path
                    d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                    stroke="#4E54C8"
                    stroke-width="2"
                  />
                </svg>
                <ThumbUpIcon
                  v-if="launchSuccess === 'prepare_set'"
                  class="w-4 h-4 text-success-400"
                />
                <ArrowRightIcon class="w-4 h-4 text-text-primary" />
              </button>
            </div>

            <div class="flex-1 flex items-stretch p-2">
              <div class="flex-1 bg-gray-800 rounded-lg border-gray-700 border p-5 flex flex-col">
                <div class="flex-1 flex gap-8">
                  <div class="flex flex-col gap-1">
                    <div class="flex gap-1 items-center">
                      <div class="h-2 w-2 rounded-sm bg-primary-500"/>
                      <h2 class="text-text-secondary text-xs">Your Avg.</h2>
                    </div>
                    <h1 class="text-text-primary text-lg font-medium">{{ formatStatStringData(selectedStat?.data?.players_value, selectedStat?.data?.unit_type) }}</h1>
                  </div>
                  <div class="flex flex-col gap-1">
                    <div class="flex gap-1 items-center">
                      <div class="h-2 w-2 rounded-sm bg-secondary-500"/>
                      <h2 class="text-text-secondary text-xs">Target Avg.</h2>
                    </div>
                    <h1 class="text-text-primary text-lg font-medium">{{ formatStatStringData(selectedStat?.data?.rating_range_average_value, selectedStat?.data?.unit_type) }}</h1>
                  </div>
                </div>
                <LineChart
                  v-if="chartData"
                  :options="options"
                  :chartData="chartData"
                  class="h-[112px]"
                />
                <div v-else class="h-[112px] flex items-center justify-center">
                  Loading chart data...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start" v-if="selectedRoutine">
        <h1 class="text-2xl font-medium text-text-primary">Coach Routines</h1>
        <Routine
          :routines="dailyRoutines"
          @launchRoutine="launchRoutine"
        />
      </div>

      <div v-else class="flex flex-col gap-4 col-span-12 sm:col-span-3 sm:justify-start">
        <h1 class="text-text-primary font-semibold text-[24px]">Recommended</h1>
        <div class="bg-gray-900 rounded-lg p-4 flex flex-col gap-2 flex-grow">
          <div class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2">
            <img :src="`/maps/map_icon_${recommendedMap?.name}.svg`" class="w-8 h-8">
          </div>
          <div class="flex gap-1 items-center">
            <h2 class="text-text-secondary text-[10px] uppercase tracking-[1.5px]">{{ recommendedMap?.friendly_name }}</h2>
            <span class="text-text-disabled text-[12px] uppercase tracking-[1.5px]">•</span>
            <h2 class="text-text-secondary text-[10px] uppercase tracking-[1.5px]">{{ recommendedMod?.modifier?.friendly_name }}</h2>
          </div>

          <h1 class="text-text-primary">
            Improve now with a recommended mod
          </h1>

          <h2 class="text-text-secondary text-xs font-light flex-1">
            Your Refrag coach has recommended {{ recommendedMod?.modifier?.friendly_name }} to improve your {{ formatStatString(recommendedStat?.data?.name) }} on your most-played map, {{ recommendedMap?.friendly_name }}. Get better now!
          </h2>

          <button @click="launchRecommendedMod(recommendedMod?.modifier?.modifier_name, false)" class="mt-auto py-3 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-gray-800 hover:bg-gray-700 flex items-center justify-center gap-2">
            Play now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import { ThumbUpIcon, ArrowRightIcon, ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { formatStatString, toTitleCase, formatData, formatStatStringData } from "../../utils/formatters";
import { createClickablePointsPlugin } from '../../utils/chartPlugins';
import { Chart, registerables } from "chart.js";
import { DoughnutChart, LineChart } from "vue-chart-3";
import Routine from "../Coach/Routine.vue"

const router = useRouter();
const store = useStore();

const emit = defineEmits(['launchRecommendedMod']);

const props = defineProps({
  user: Object,
  showMatches: Boolean,
  userStatsHistory: Array,
});

// Move refs from HomePage
const userStats = ref();
const allStats = ref([]);
const userStatsHistory = ref([]);
const selectedStat = ref(null);
const selectedStatMod = ref(null);
const statAverage = ref(0);
const mappedStats = ref([]);
const recommendedMap = ref(null);
const userSettings = ref(null);
const mostPlayedMaps = ref([]);
const recommendedMod = ref(null);
const activeServer = computed(() => store.getters.activeServer);
const routineLengths = ref(["Short", "Medium", "Long"]);
const selectedLength = ref("Short");
const dailyRoutines = ref([]);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const recommendedStat = computed(() => {
  return userStats.value?.[0];
});

const selectedRoutine = computed(() => {
  return dailyRoutines.value?.find(routine => {
    if (selectedLength.value === "Short") {
      return routine.duration_in_minutes === 15;
    } else if (selectedLength.value === "Medium") {
      return routine.duration_in_minutes === 30;
    } else if (selectedLength.value === "Long") {
      return routine.duration_in_minutes === 45;
    }
  });
});

// Create the plugin
const clickablePointsPlugin = createClickablePointsPlugin(
  (index) => userStatsHistory.value[userStatsHistory.value.length - 1 - index]?.match_id,
  (matchId) => router.push(`/matches/${matchId}`)
);

// Register the plugin
Chart.register(...registerables, clickablePointsPlugin);

// Add computed properties
const slidingBorderPosition = computed(() => {
  const index = userStats.value?.findIndex(stat => stat.data.name === selectedStat.value?.data?.name);
  return `${index * 33.33}%`;
});

// Add functions
function selectStat(stat) {
  selectedStat.value = stat;
  updateSelectedStatMod();
  updateUserStatHistory(stat);
}

function getDailyRoutines() {
  axios({
    url: `/task_lists/my_daily_routine`,
    method: "GET",
  })
    .then((res) => {
      dailyRoutines.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

getDailyRoutines();

function launchRoutine(routine) {
  let task = routine.sections[0].tasks[0];
  if (!activeServer.value?.id) {
    serverInfo.value = {
      cs_map: task.cs_map,
      mode: task.mod,
      content: {
        id: parseInt(task.id),
        title: task.name,
        type: "task",
        task_list_id: selectedRoutine.value.id,
        task_list_section_id: task.section_id,
        task_list_difficulty: 0,
        is_exam: false,
      },
    };
    showServerModal.value = true;
    return;
  }
  launchLoading.value = true;
  axios({
    url: `/cs_servers/${activeServer.value?.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    params: {
      command: "launch_task",
      value: `${routine.id}, ${task.section_id}, ${task.id}, 0, 0`,
      is_client_command: false,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = false;
      launchSuccess.value = true;
      setTimeout(() => {
        launchSuccess.value = false;
      }, 2000);
    })
    .catch((err) => {
      launchLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: `Could not execute server command!`,
      });
    });
}

function launchRecommendedMod(mod, is_practice) {
  const ttk = allStats.value.time_to_kill?.players_value ? allStats.value.time_to_kill.players_value / 1000000000 : null;
  emit('launchRecommendedMod', mod, is_practice, ttk, recommendedMap.value);
}

function findWorstDeviationsAway(stats) {
  const worstStats = [];

  Object.keys(stats).forEach((statKey) => {
    const stat = stats[statKey];

    if (stat.deviations_away !== undefined && stat.recommended_mods) {
      const playersValue = stat.players_value;
      const averageValue = stat.rating_range_average_value;
      let percentageDifference = 0;

      if (averageValue !== 0) {
        if (stat.is_ascending) {
          percentageDifference = ((playersValue - averageValue) / averageValue) * 100;
        } else {
          percentageDifference = ((averageValue - playersValue) / averageValue) * 100;
        }
      }

      stat.percent_difference = percentageDifference.toFixed(2);
      stat.is_negative = percentageDifference < 0;
      worstStats.push({title: statKey, data: stat});
    }
  });

  worstStats.sort((a, b) => {
    const diffA = parseFloat(a.data.percent_difference);
    const diffB = parseFloat(b.data.percent_difference);
    return diffA - diffB;
  });
  return worstStats.slice(0, 3);
}

function updateSelectedStatMod() {
  selectedStatMod.value = getMod(selectedStat.value?.data?.recommended_mods);
}

function updateUserStatHistory(stat) {
  let mappedStatHistory = props.userStatsHistory?.map((match) => {
    return formatData(match[selectedStat.value?.title], stat?.data?.unit_type);
  });

  statAverage.value = formatData(stat?.data?.rating_range_average_value, stat?.data?.unit_type);
  mappedStats.value = mappedStatHistory;
}

function getMod(mods) {
  if (!mods || mods.length === 0) {
    return null;
  }

  // Calculate total weight of all mods
  const totalWeight = mods.reduce((sum, mod) => sum + mod.value, 0);

  // Generate random value between 0 and total weight
  const random = Math.random() * totalWeight;

  // Find the mod where random falls within its weight range
  let weightSum = 0;
  for (const mod of mods) {
    weightSum += mod.value;
    if (random <= weightSum) {
      return mod;
    }
  }

  // Fallback to first mod if something goes wrong
  return mods[0];
}

// Chart options
const options = computed(() => ({
  spanGaps: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    clickablePoints: clickablePointsPlugin,
  },
  elements: {
    point: {
      radius: 4,
      hoverRadius: 6,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        color: 'transparent',
        borderColor: 'transparent'
      },
    },
    y: {
      grid: {
        borderDash: [5, 5],
        color: '#37485D',
        borderColor: 'transparent'
      },
      ticks: {
        color: '#9CADC4',
        maxTicksLimit: 5
      },
      beginAtZero: true,
      suggestedMin: 0,
    }
  },
  maintainAspectRatio: false,
  responsive: true,
}));

// Chart data
const chartData = computed(() => {
  if (props.userStatsHistory.error || props.userStatsHistory.length === 0 || !selectedStat.value) {
    return null;
  }

  const averageValue = formatData(
    selectedStat.value.data.rating_range_average_value,
    selectedStat.value.data.unit_type
  );

  const reversedLabels = [...props.userStatsHistory].reverse().map(match => (match.played_on || match.date).split('T')[0]);
  const reversedData = [...props.userStatsHistory].reverse().map(match =>
    formatData(match[selectedStat.value.title], selectedStat.value.data.unit_type)
  );

  return {
    labels: reversedLabels,
    datasets: [
      {
        data: reversedData,
        fill: true,
        backgroundColor: "#6B70D115",
        borderColor: "#6B70D1",
        tension: 0,
        pointRadius: 3,
        pointHoverBackgroundColor: '#6B70D1',
        pointHoverBorderColor: '#FFFFFF',
      },
      {
        data: Array(props.userStatsHistory.length).fill(averageValue),
        fill: false,
        borderColor: "#C44CB6",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  }
});

// Add data fetching
function getRefragCoachData() {
  if (props.user.access_level > 0) {
    axios.get(`/matches/user_profile`, {
      params: {
        'steam_id': props.user.steam_id,
      },
    }).then((response) => {
      allStats.value = response?.data?.stats || [];
      userStats.value = findWorstDeviationsAway(response?.data?.stats) || [];
      mostPlayedMaps.value = response?.data?.extra?.most_played_maps;
      setRecommendedMap();
      setRecommendedMod();
      selectedStat.value = userStats.value[0];
      updateSelectedStatMod();
    });
  }
}

function setRecommendedMod() {
  recommendedMod.value = getMod(recommendedStat.value?.data?.recommended_mods);
}

function setRecommendedMap() {
  if (!mostPlayedMaps.value || mostPlayedMaps.value.length === 0) {
    return;
  }

  for (const mapName of mostPlayedMaps.value) {
    const csMap = store.state.modInfo?.mods[0]?.cs_maps.find(m => m.name === mapName);
    if (csMap) {
      recommendedMap.value = csMap;
      return;
    }
  }
}

// Watch for modInfo changes
watch(() => store.getters.modInfo, () => {
  if(recommendedMap.value) return;

  setRecommendedMap();
  setRecommendedMod();
});

// Initialize data on mount
onMounted(() => {
  getRefragCoachData();
});
</script>

<style scoped>
.sliding-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 33.33%;
  background-color: theme('colors.primary.500');
  transition: left 0.3s ease-in-out;
}
.button-bg {
  border-radius: var(--Radius-radius-circle, 400px);
  border: 1px solid #3a3b5e;
  background: linear-gradient(180deg, rgba(78, 84, 200, 0.30) 0%, rgba(38, 41, 98, 0.30) 100%);
}
.topography-bg {
  background-image: url('../assets/topography-two.svg');
  background-repeat: repeat;
  background-size: 500px 500px;
}
</style>
